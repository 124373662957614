import React from 'react'
import OwlCarousel from 'react-owl-carousel'; 

const options = {
  margin: 30,
  responsiveClass: true,
  nav: false,
  autoplay: true,
  loop: true,
  smartSpeed: 1000,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 1,
      },
      600: {
          items: 1,
      },
      700: {
          items: 1,
      },
      1000: {
          items: 1,
      }
  },
};

function OurServices() {
    return (
        <div>
                        
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <div class="d-flex justify-content-between align-items-center">
            <h2>Our Core Services</h2>
            <ol>
              <li><a href="index.html">Home</a></li>
              <li>Core Services</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}

      {/* <!-- ======= Portfolio Details Section ======= --> */}

       
<OwlCarousel className="slider-items owl-carousel" {...options}>
          
          <div class="portfolio-details-carousel">
              <img
                src="assets/img/portfolio/portfolio-details-1.jpeg"
                class="img-fluid"
                alt=""
              />
              </div>
               <div class="portfolio-details-carousel">
              <img
                src="assets/img/portfolio/portfolio-details-2.jpeg"
                class="img-fluid"
                alt=""
              />
              </div>
               <div class="portfolio-details-carousel">
              <img
                src="assets/img/portfolio/portfolio-details-3.jpeg"
                class="img-fluid"
                alt=""/>
            </div>
          </OwlCarousel>


           {/* <!-- ======= Portfolio Section ======= --> */}
          <section id="portfolio" class="portfolio">
            <div class="container" data-aos="fade-up">
              <div class="section-title">
                <h2>OUR CORE SERVICES</h2>
                <p></p>
              </div>

              <div class="row portfolio-container">
                <div class="col-lg-4 col-md-6 portfolio-item">
                  <div class="portfolio-wrap">
                    <div class="testimonial-item">
                      <p class="osv" id="c1">
                        <img src="assets/img/s9.svg" class="img-fluid" alt="" />
                        <span class="ti"><br/><b>Mobile Apps</b><br/></span>
                       We build intuitive engaging Android , ios and Cross-platform apps for businesses, Consumers and enterprises that end users love and adapt to them very quickly
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item">
                  <div class="portfolio-wrap">
                    <div class="testimonial-item">
                      <p class="osv" id="c2">
                        <img src="assets/img/s2.svg" class="img-fluid" alt="" />
                        <span class="ti"><br/><b>Web Development</b><br/></span>
                       Our Web Developers create custom web and web application solutions. We deliver web presence to help you grow your business using the best web technologies.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item">
                  <div class="portfolio-wrap">
                    <div class="testimonial-item">
                      <p class="osv" id="c3">
                        <img src="assets/img/s3.svg" class="img-fluid" alt="" />
                        <span class="ti"><br/><b>UI/Ux Design</b><br
                        /></span>
                      Starting from concept , information architecture , Visual identity and UI/UX design , our team delivers dazzling experiences for maximum user engagement.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item">
                  <div class="portfolio-wrap">
                    <div class="testimonial-item">
                      <p class="osv" id="c4">
                        <img src="assets/img/s4.svg" class="img-fluid" alt="" />
                        <span class="ti"><br/><b>DevOps</b><br
                        /></span>
                       Our DevOps experts help you with secure & stable infrastructure management for Continuous integration and Continuous Delivery for faster releases.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item">
                  <div class="portfolio-wrap">
                    <div class="testimonial-item">
                      <p class="osv" id="c5">
                        <img src="assets/img/s5.svg" class="img-fluid" alt="" />
                        <span class="ti"><br/><b>IoT</b><br
                        /></span>
                       Hire IoT programmers who devlop bespoke and innovative IoT solutions to simplify and automate complex business processes to save overall operational cost.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item">
                  <div class="portfolio-wrap">
                    <div class="testimonial-item">
                      <p class="osv" id="c6">
                        <img src="assets/img/s6.svg" class="img-fluid" alt="" />
                        <span class="ti"><br/><b>Gaming</b><br
                        /></span>
                        We've been renowned for delivering interactive , visually appealing and exciting gaming experiences on mobile , Console and Virtual Reality Platforms.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item">
                  <div class="portfolio-wrap">
                    <div class="testimonial-item">
                      <p class="osv" id="c7">
                        <img src="assets/img/s7.svg" class="img-fluid" alt="" />
                        <span class="ti"><br/><b>AI / ML</b><br
                        /></span>
                       We infuse AI elements to existing business processes to boost productivity. Our AI engineers are experts to deliver next-gen AI apps & solutions to delivers industries.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item">
                  <div class="portfolio-wrap">
                    <div class="testimonial-item">
                      <p class="osv" id="c8">
                        <img src="assets/img/s8.svg" class="img-fluid" alt="" />
                        <span class="ti"><br/><b>QA Services</b><br
                        /></span>
                        Our QA engineers don't just test , they make your software application successful ensuring quality delivery with expert manual and automated testing services.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item">
                  <div class="portfolio-wrap">
                    <div class="testimonial-item">
                      <p class="osv" id="c9">
                        <img src="assets/img/s9.svg" class="img-fluid" alt="" />
                        <span class="ti"><br/><b>Hire Dedicated Developers</b><br
                        /></span>
                       Create Your own team of developers for your software development projects on short term , long term or permanent basis with guaranteed project delivery at affordable price.
                      </p>
                    </div>
                  </div>
                </div>

                
                <div class="col-lg-4 col-md-6 portfolio-item">
                  <div class="portfolio-wrap">
                    <div class="testimonial-item">
                      <p class="osv" id="c1">
                        <img src="assets/img/s2.svg" class="img-fluid" alt="" />
                        <span class="ti"><br/><b>SEO</b><br
                        /></span>
                       Want to get more earned traffic, leads, and sales? Start where 93% of online experiences start—with search engines. Grow and get more qualified website traffic, leads, and sales with SEO. Also get suggestion to improve your ranking!
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item">
                  <div class="portfolio-wrap">
                    <div class="testimonial-item">
                      <p class="osv" id="c2">
                        <img src="assets/img/s3.svg" class="img-fluid" alt="" />
                        <span class="ti"><br/><b>digital marketing</b><br
                        /></span>
                       Get Found, Get Noticed, and Get Leads with Digital Marketing Services! Using our service , generate more website traffic, leads, and sales. contact us to learn more about the individual services we provide.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item">
                  <div class="portfolio-wrap">
                    <div class="testimonial-item">
                      <p class="osv" id="c3">
                        <img src="assets/img/s8.svg" class="img-fluid" alt="" />
                        <span class="ti"><br/><b>setup your Startup</b><br/></span>
                        Hire our brain to think about your
                        Brand/Startup/Business. Helping create and launch
                        scalable startups.<br />
                       To grow your idea from your company registration till the project launch , everything will be handled by DiflinTech!
                      </p>
                    </div>
                  </div>
                </div>
          
              </div>
            </div>
          </section>
          {/* <!-- End Portfolio Section -> */}

        </div>
    )
}

export default OurServices
