import React from 'react';  
import './App.css';  
import Layout  from './Layout/Layout';
import Homepage from './pages/Homepage';   
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';  
function App() {  
  return (  
     <div className="App">  

      <Router>
               <Switch>
                     <Route path="/our-team/dev">
                     <Homepage />
                    </Route>
                     <Route path="/">
                     <Layout/>
                    </Route>
                </Switch>
      </Router>
         
     </div>  
  );  
  
}  
  
export default App; 