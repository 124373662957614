import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"; 

export class Header extends Component {  
    render() {  
        return (  
            <div>
<Navbar collapseOnSelect expand="lg"  bg="info" variant="dark" fixed="top">
   <Navbar.Brand href="">
      <img
        alt=""
        src="assets/img/logo.jpeg"
        width="100"
        height="40"
        className="d-inline-block align-top"
      /></Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />

  <Navbar.Collapse id="responsive-navbar-nav" className="mr-auto">
    <Nav className='ml-auto'>

<Router>
  <Switch>
    <Route path="/our-services"></Route>
    <Route path="/our-team"></Route>
    <Route path="/">
      <Nav.Link href="#about" className='m-auto'><b>About</b></Nav.Link>
      <Nav.Link href="#services"  className='m-auto'><b>Services</b></Nav.Link>
      <Nav.Link href="#why"  className='m-auto'><b>Why Us</b></Nav.Link>
      <Nav.Link href="#tech"  className='m-auto'><b>Tech Tools</b></Nav.Link>
      <Nav style={{ marginLeft: '2.4rem'}}></Nav>
    </Route>
  </Switch>
</Router>
      <div class="so-link text-center text-md-right pt-3 pt-md-0">
            <a href="https://www.facebook.com/techdiflin/" class="facebook"><i class="bx bxl-facebook"></i></a>
            <a href="https://www.instagram.com/techdiflin/" class="instagram"><i class="bx bxl-instagram"></i></a>
            <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
            <a href="https://twitter.com/Diflin2?s=08" class="twitter"><i class="bx bxl-twitter"></i></a>
      </div>
<br/>
    <Nav style={{ marginLeft: '2.4rem' , textAlign: "center" }}>
   <a href="#contact" class="get-started-btn"> &nbsp;Contact Us &nbsp;</a>
    </Nav>

    </Nav>
  </Navbar.Collapse>
  
</Navbar>
            </div>  
        )  
    }  
}  
  
export default Header  