import React, { Component} from 'react';  
import Header from './Header'  
import Footer from './Footer'  
import Index from './Index'
import OurTeam from './OurTeam'
import OurServices from './OurServices'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"; 



export class Layout extends Component {  
    render() {  
        return (  
            <div className="app">
            <Header />
               <Router>
               <Switch>
                     <Route path="/our-services">

                     <OurServices/>
                    </Route>
                     <Route path="/our-team">
                     <OurTeam/>
                    </Route>
                     <Route path="/">
                     <Index/>
                    </Route>
                </Switch>
             </Router>
        
              <Footer />
            </div>  
        )  
    }  
}  
  
export default Layout  