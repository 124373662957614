import React from 'react'

function OurTeam() {
    return (
        <div>

        {/* <!-- ======= Breadcrumbs ======= --> */}
      <section class="breadcrumbs">
        <div class="container">
          <div class="d-flex justify-content-between align-items-center">
            <h2 id="oct">Our Core Team</h2>
            <ol>
              <li><a href="index.html">Home</a></li>
              <li>Our Core Team</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs -->
      <!-- ======= We Simplify Software Development Process Section ======= --> */}
      <section
        id="about"
        class="features1" >
        <div class="container">
          <div class="section-title">
            <h2><b>
              we are nothing without our developers
              </b>
            </h2>
          </div>

          <p class="ti">
            Our company is nothing without our devlopers. Team work and active
            participation of every member turned out to be the secret of our
            success.
          </p>
        </div>
      </section>
      {/* <!-- End We Simplify Software Development Process--> */}

      <section>
        <div class="container" id="about">
          {/* <!-- ********* <3 ********* THREE ********* <3 ********* --> */}
          <div class="r">
            <ul class="three">
              <li class="transition">
                <div class="wrapper">
                  <img
                    class="transition"
                    src="assets/img/testimonials/testimonials-1.jpeg"
                  />
                  <ul class="social about">
                    <li>Co-Founder and CEO <br />Find him on:</li>
                    <br />
                    <a href="https://www.facebook.com/sudipta.dolui.16" class="facebook"><i class="bx bxl-facebook"></i></a>
                    <a
                      href="https://www.instagram.com/sudiptadolui_/"
                      class="instagram"
                      ><i class="bx bxl-instagram"></i></a>
                    <a href="https://www.linkedin.com/in/sudipta-dolui-5226161b0/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                    <a href="https://twitter.com/Diflin2?s=08" class="twitter"><i class="bx bxl-twitter"></i></a>
                  </ul>
                  <span class="transition">
                    <h3>Sudipta Dolui<em>CHIEF EXECUTIVE OFFICER</em></h3>
                    <img
                      class="mamber-img"
                      src="assets/img/testimonials/testimonials-1.jpeg"
                    />
                    <h3>Sudipta Dolui<em>CHIEF EXECUTIVE OFFICER</em></h3>
                    <i>more</i>
                  </span>
                </div>
              </li>

              <li class="transition">
                <div class="wrapper">
                  <img
                    class="transition"
                    src="assets/img/testimonials/testimonials-2.jpeg"
                  />
                  <ul class="social about">
                    <li>Co-Founder and CTO <br />Find him on:</li>
                    <br />
                    <a href="https://www.facebook.com/sudipta.rana.27" class="facebook"><i class="bx bxl-facebook"></i></a>
                    <a
                      href="https://www.instagram.com/rana.sudipta.27/"
                      class="instagram"><i class="bx bxl-instagram"></i></a>
                    <a href="https://www.linkedin.com/in/sudipta-rana/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                    <a href="https://twitter.com/Diflin2?s=08" class="twitter"><i class="bx bxl-twitter"></i></a>
                  </ul>
                  <span class="transition">
                    <h3>Sudipta Rana<em>CHIEF TECHNOLOGY OFFICER</em></h3>
                    <img
                      class="mamber-img"
                      src="assets/img/testimonials/testimonials-2.jpeg"
                    />
                    <h3>Sudipta Rana<em>CHIEF TECHNOLOGY OFFICER</em></h3>
                    <i>more</i>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          {/* <!-- ********* <3 ********* THREE ********* <3 ********* --> */}

          <div class="section-title" id="team">
            <br />
            <h2>Our Core Team</h2>
          </div>

          <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="our-team">
                <div class="picture">
                  <img
                    class="img-fluid"
                    src="assets/img/testimonials/testimonials-3.jpeg"
                  />
                </div>
                <div class="team-content">
                  <h3 class="name">A.K Sharma</h3>
                  <h4 class="title">App Development Team Leader</h4>
                </div>
                <ul class="social">
                  <li></li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="our-team">
                <div class="picture">
                  <img
                    class="img-fluid"
                    src="assets/img/testimonials/testimonials-4.jpeg"
                  />
                </div>
                <div class="team-content">
                  <h3 class="name">M C Mishra</h3>
                  <h4 class="title">AI/ML Development Team Leader</h4>
                </div>
                <ul class="social">
                  <li></li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="our-team">
                <div class="picture">
                  <img
                    class="img-fluid"
                    src="assets/img/testimonials/testimonials-5.jpeg"
                  />
                </div>
                <div class="team-content">
                  <h3 class="name">S Ilyas</h3>
                  <h4 class="title">App Development Team Leader</h4>
                </div>
                <ul class="social">
                  <li></li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="our-team">
                <div class="picture">
                  <img
                    class="img-fluid"
                    src="assets/img/testimonials/testimonials-6.jpeg"
                  />
                </div>
                <div class="team-content">
                  <h3 class="name">J Gorai</h3>
                  <h4 class="title">Marketing Team Leader</h4>
                </div>
                <ul class="social">
                  <li></li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="our-team">
                <div class="picture">
                  <img
                    class="img-fluid"
                    src="assets/img/testimonials/testimonials-10.jpeg"
                  />
                </div>
                <div class="team-content">
                  <h3 class="name">R Banerjee</h3>
                  <h4 class="title">director of HR</h4>
                </div>
                <ul class="social">
                  <li></li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="our-team">
                <div class="picture">
                  <img
                    class="img-fluid"
                    src="assets/img/testimonials/testimonials-7.jpeg"
                  />
                </div>
                <div class="team-content">
                  <h3 class="name">D Banerjee</h3>
                  <h4 class="title">Web Development Team Leader</h4>
                </div>
                <ul class="social">
                  <li></li>
                </ul>
              </div>
            </div>
            
             <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="our-team">
                <div class="picture">
                  <img
                    class="img-fluid"
                    src="assets/img/testimonials/testimonials-11.jpeg"
                  />
                </div>
                <div class="team-content">
                  <h3 class="name">A Pandey</h3>
                  <h4 class="title">Digital Marketing team leader</h4>
                </div>
                <ul class="social">
                  <li></li>
                </ul>
              </div>
            </div>
            
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="our-team">
                <div class="picture">
                  <img
                    class="img-fluid"
                    src="assets/img/testimonials/testimonials-8.jpeg"
                  />
                </div>
                <div class="team-content">
                  <h3 class="name">V.S Joshi</h3>
                  <h4 class="title">Game Development Team Leader</h4>
                </div>
                <ul class="social">
                  <li></li>
                </ul>
              </div>
            </div>
           
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <a href="http://localhost:3000/our-team/dev">
              <div class="our-team">
                <div class="picture">
                  <img
                    class="img-fluid"
                    src="assets/img/testimonials/testimonials-9.jpeg"
                  />
                </div>
                <div class="team-content">
                  <h3 class="name">A Sahoo</h3>
                  <h4 class="title">MREN Stack Developer</h4>
                </div>
                <ul class="social">
                  <li></li>
                </ul>
              </div>
              </a>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="our-team">
                <div class="picture">
                  <img
                    class="img-fluid"
                    src="assets/img/testimonials/testimonials-13.jpeg"
                  />
                </div>
                <div class="team-content">
                  <h3 class="name">S Ghosal</h3>
                  <h4 class="title">Ai / ML developer</h4>
                </div>
                <ul class="social">
                  <li></li>
                </ul>
              </div>
            </div>
           
           
            
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="our-team">
                <div class="picture">
                  <img
                    class="img-fluid"
                    src="assets/img/testimonials/testimonials-14.jpeg"
                  />
                </div>
                <div class="team-content">
                  <h3 class="name">A.K Gupta</h3>
                  <h4 class="title">SEO developer</h4>
                </div>
                <ul class="social">
                  <li></li>
                </ul>
              </div>
            </div>
            
             <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="our-team">
                <div class="picture">
                  <img
                    class="img-fluid"
                    src="assets/img/testimonials/testimonials-12.jpeg"
                  />
                </div>
                <div class="team-content">
                  <h3 class="name">T Bag</h3>
                  <h4 class="title">Laravel Developer</h4>
                </div>
                <ul class="social">
                  <li></li>
                </ul>
              </div>
            </div>
            
          </div>
        </div>
      </section>


        </div>
    )
}

export default OurTeam
