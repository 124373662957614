import React, { Component } from 'react';
import 'boxicons';
import emailjs from 'emailjs-com';

export class Footer extends Component {  
    render() {  
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('gmail', 'DiflinTemplate', e.target, 'user_8jdZDMljhrWr79VC0470p')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  }

  function sendEmail1(e) {
    e.preventDefault();

    emailjs.sendForm('gmail', 'DiflinTemplate1', e.target, 'user_8jdZDMljhrWr79VC0470p')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  }

  
        return (  
<div> 
    {/* <!-- ======= Contact Section ======= --> */}
      <section id="contact" class="contact section-bg">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h3><b>
              Drop us a line to Start a Project with us
           </b> </h3>
          </div>

          <div class="row">
            <div class="col-lg-6" id="contact1">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <a href="javascript:void(Tawk_API.toggle())">
                    <div class="info-box mb-4">
                      <img
                        src="assets/img/contact4.svg"
                        class="img-fluid"
                        alt=""
                      />
                      <p>Begin a quick discussion</p>
                      <h3>Live Chat with Us</h3>
                    </div></a>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <a href="https://api.whatsapp.com/send?phone=918972710178">
                    <div class="info-box mb-4">
                      <img
                        src="assets/img/contact3.svg"
                        class="img-fluid"
                        alt=""
                      />
                      <p>Ready to share a Project?</p>
                      <h3>Connect on WhatsApp</h3>
                    </div></a>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <a href="tel:+91 8972710178">
                    <div class="info-box mb-4">
                      <img
                        src="assets/img/contact1.png"
                        class="img-fluid"
                        alt="" id="hai"
                      />
                      <p>Have an idea ?</p>
                      <h3>Call us AnyTime</h3>
                    </div></a>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <a href="mailto:info.diflin@gmail.com">
                    <div class="info-box mb-4">
                      <img
                        src="assets/img/contact2.svg"
                        class="img-fluid"
                        alt=""/>
                      <p>Share a detailed specification</p>
                      <h3>info.diflin@gmail.com</h3>
                    </div></a>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <form onSubmit={sendEmail}
                class="php-email-form">
                <div class="form-row">
                  <div class="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      id="name"
                      placeholder="Your Name"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 4 chars"/>
                    <div class="validate"></div>
                  </div>
                  <div class="col-md-6 form-group">
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      data-rule="email"
                      data-msg="Please enter a valid email"/>
                    <div class="validate"></div>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 8 chars of subject"/>
                  <div class="validate"></div>
                </div>
                <div class="form-group">
                  <textarea
                    class="form-control"
                    name="message"
                    rows="5"
                    data-rule="required"
                    data-msg="Please write something for us"
                    placeholder="Message"></textarea>
                  <div class="validate"></div>
                </div>
                <div class="mb-3">
                  <div class="loading">Loading</div>
                  <div class="error-message"></div>
                  <div class="sent-message">Your message has been sent. Thank you!</div>
                </div>
                <div class="text-center">
                  <button type="submit">Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Contact Section --> */}
     {/* <!-- ======= Footer ======= --> */}
    <footer id="footer">
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-6 footer-contact">
              <h3>Diflin Tech</h3>
              <p>KOLKATA , INDIA <br /></p>
            </div>
            <div class="col-lg-3 col-md-6 footer-links">
              <h4>Contact us</h4>
              <a href="tel:+91 8972710178" class='f-col'><strong>Phone:</strong> +91 8972710178</a><br />
              <br /><a href="mailto:info.diflin@gmail.com" class='f-col'><strong>Email:</strong> info.diflin@gmail.com<br/></a>
            </div>
            <div class="col-lg-2 col-md-6 footer-links">
              <h4>More Useful Links</h4>
              <ul>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <a href="#">Terms of service</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <a href="#">Privacy policy</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-4 col-md-6 footer-newsletter">
              <h4>Join Our Newsletter</h4>
              <form onSubmit={sendEmail1}>
                <input type="email" name="email" /><input type="submit" value="Subscribe"/>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="copyright-wrap d-md-flex py-4">
          <div class="mr-md-auto text-center text-md-left">
            <div class="copyright"> &copy; <strong><span>Diflin</span></strong>. All Rights Reserved</div>
            <div class="credits">Devloped by <a href="">DiflinTech</a></div>
          </div>

          <div class="so-link text-center text-md-right pt-3 pt-md-0">
            <a href="https://www.facebook.com/techdiflin/" class="facebook"><i class="bx bxl-facebook"></i></a>
            <a href="https://www.instagram.com/techdiflin/" class="instagram"><i class="bx bxl-instagram"></i></a>
            <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
            <a href="https://twitter.com/Diflin2?s=08" class="twitter"><i class="bx bxl-twitter"></i></a>
          </div>
        </div>
        
      </div>
      <div><br></br> <br/><br/></div>
    </footer>
    {/* <!-- End Footer --> */}

    <nav class="nav">
       <a href="/" class="nav__link">
        <i class="material-icons nav__icon">dashboard</i>
        <span class="nav__text">Home</span></a>
  
      <a href="our-services" class="nav__link">
        <i class="material-icons nav__icon">settings</i>
        <span class="nav__text">Services</span>
      </a>

      <a href="our-team" class="nav__link">
        <i class="material-icons nav__icon">person</i>
        <span class="nav__text">Team</span>
      </a>

      <a href="#contact" class="nav__link">
        <i class="material-icons nav__icon">perm_phone_msg</i>
        <span class="nav__text">Contact Us</span>
      </a>
      <a class="nav__link">
        <span class="nav__text"> </span>
      </a>
    </nav>


    <a href="#" class="back-to-top"><i class="icofont-simple-up"></i></a>
</div>  
        )  
    }  
}  
export default Footer