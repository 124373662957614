import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel';   
import Typed from 'react-typed';


//Owl Carousel Settings
const options = {
  margin: 30,
  responsiveClass: true,
  nav: false,
  autoplay: true,
  loop: true,
  smartSpeed: 1000,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 1,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 3,
      }
  },
};

export class Index extends Component {  
    render() {  
        return (  
    <div>
        {/* <!-- ======= Hero Section ======= --> */}
    <section id="hero" class="d-flex align-items-center">
      <div class="container-fluid" data-aos="fade-up">
        <div class="row justify-content-center">
          <div
            class="col-xl-5 col-lg-6 pt-3 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center"
          >
            <h1>
            <Typed
                    strings={['App Development',
                              'Web Development',
                              'Setup Your Startup',
                              'Digital Marketing']}
                    typeSpeed={40}
                    backSpeed={50}
                    loop
                />
             <br />With DiflinTech</h1>
            <h2>
              We provide tech services , with 100% project delivery success at
              affordable prices.
            </h2>
            <div>
              <a href="#about" class="btn-get-started scrollto">Get Started</a>
            </div>
          </div>
          <div
            class="col-xl-4 col-lg-6 order-1 order-lg-2 hero-img"
            data-aos="zoom-in"
            data-aos-delay="150"
          >
         
            <img
              src="assets/img/hero-img.svg"
              class="img-fluid animated"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
    {/* <!-- End Hero --> */}


          {/* <!-- ======= We Simplify Software Development Process Section ======= --> */}
      <section id="about" class="features">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              We Simplify Software Development Process
            </h2>
          </div>

          <div class="row">
            <div
              class="col-lg-6 order-2 order-lg-1 d-flex flex-column align-items-lg-center">
              <div
                class="icon-box mt-5 mt-lg-0"
                data-aos="fade-up"
                data-aos-delay="100">
                <span>
                  <img src="assets/img/p1.svg" alt="" class="img-fluid" />
                  <h4 class="abt">
                    Discover
                  </h4></span>
                <p>
                  We shape brands through exploration, applying in-depth
                  research to challenge assumptions at every turn.
                </p>
              </div>
              <div
                class="icon-box mt-5"
                data-aos="fade-up"
                data-aos-delay="200">
                <span>
                  <img src="assets/img/p2.svg" alt="" class="img-fluid" />
                  <h4 class="abt">
                    Design
                  </h4></span>
                <p>
                  Our design approach is to simplify. We embrace the joy in
                  creating something unique that is easy for end users.
                </p>
              </div>
              <div class="icon-box mt-5" data-aos="fade-up" data-aos-delay="50">
                <span>
                  <img src="assets/img/p3.svg" alt="" class="img-fluid" />
                  <h4 class="abt">Build</h4></span>
                <p>
                  Using modern technologies, we build with efficiency and skill,
                  creating flexible and scalable business-driven solutions.
                </p>
              </div>
              <div class="icon-box mt-5" data-aos="fade-up" data-aos-delay="50">
                <span>
                  <img src="assets/img/p4.svg" alt="" class="img-fluid" />
                  <h4 class="abt">
                    Deliver
                  </h4></span>
                <p>
                  We take an iterative approach to both our work and our
                  practice, always looking for ways to improve what we do.
                </p>
              </div>
            </div>
            <div
              class="image col-lg-6 order-1 order-lg-2"
              data-aos="zoom-in"
              data-aos-delay="50">
              <img src="assets/img/features.svg" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End We Simplify Software Development Process--> */}

    {/* <!-- ======= Our core services ======= --> */}
      <section id="services" class="testimonials section-bg">
        <div
          class="container"
          data-aos="fade-up"
        >
          <div class="section-title">
            <h2>Our Core Services</h2>
          </div>
    <div class='container-fluid' >
     <div class="testimonials-carousel"> 
     <div class="testimonial-item ti">           
        <OwlCarousel className="slider-items owl-carousel" {...options}>
           <div class="item">
               <p id="c1">
                <img src="assets/img/s1.svg" class="img-fluid" alt="" />
                <span 
                  >Mobile Apps<br
                /></span>
                We build intuitive engaging Android , ios and Cross-platform
                apps for businesses, Consumers and enterprises that end users
                love and adapt to them very quickly
              </p>
           </div>  
           <div class="item">
               <p id="c2">
                <img src="assets/img/s2.svg" class="img-fluid" alt="" />
                <span 
                  >Web Development<br
                /></span>
                Our Web Developers create custom web and web application
                solutions. We deliver web presence to help you grow your
                business using the best web technologies in todays market.
              </p>
           </div>  
           <div>
               <p id="c3">
                <img src="assets/img/s3.svg" class="img-fluid" alt="" />
                <span 
                  >UI/Ux Design<br
                /></span>
                Starting from concept , information architecture , Visual
                identity and UI/UX design , our team delivers dazzling
                experiences for maximum user engagement and design digital
                product innovation firm that transforms business.
              </p>
           </div>  
           <div class="item">
               <p id="c4">
                <img src="assets/img/s4.svg" class="img-fluid" alt="" />
                <span 
                  >DevOps<br
                /></span>
                Our DevOps experts help you with secure & stable infrastructure
                management for Continuous integration and Continuous Delivery
                for faster releases.
              </p>
           </div>  
           <div class="item">
               <p id="c5">
                <img src="assets/img/s5.svg" class="img-fluid" alt="" />
                <span 
                  >IoT<br
                /></span>
                Hire IoT programmers to innovative IoT solutions to automate
                complex business processes to save overall operational cost.
              </p>
           </div>  
           <div class="item">
                <p id="c6">
                <img src="assets/img/s6.svg" class="img-fluid" alt="" />
                <span 
                  >Gaming<br
                /></span>
                We've been renowned for delivering interactive , visually
                appealing and exciting gaming experiences on mobile , Console
                and Virtual Reality Platforms.
              </p>
           </div>  
           <div class="item"> 
               <p id="c7">
                <img src="assets/img/s7.svg" class="img-fluid" alt="" />
                <span 
                  >AI / ML<br
                /></span>
                We infuse AI elements to existing business processes to boost
                productivity. Our AI engineers are experts to deliver next-gen
                AI apps & solutions to delivers industries.
              </p>
           </div>
           <div class="item"> 
               <p id="c8">
                <img src="assets/img/s8.svg" class="img-fluid" alt="" />
                <span>QA Services<br/></span>
                Our QA engineers don't just test , they make your software
                application successful ensuring quality delivery with expert
                manual and automated testing services. We take an iterative
                approach to both our devlopment and our testing always looking
                for ways to improve what we do.
              </p>
           </div>
           <div class="item">
               <p id="c9">
                <img src="assets/img/s9.svg" class="img-fluid" alt="" />
                <span
                  >Hire Dedicated Developers<br
                /></span>
                Create Your own team of developers for your software development
                projects on short term , long term or permanent basis with
                guaranteed project delivery at affordable price. To grow your
                idea from company registration till the project launch ,
                everything handled by that Team.
              </p>
           </div>  
      </OwlCarousel> </div> </div> 
    </div>  

     </div>
    </section>
      {/* <!-- End Our core services Section --> */}

          {/* <!-- ======= We Promise. We Deliver. ======= --> */}
      <section id="why" class="about">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-6 order-1 order-lg-2"
              data-aos="zoom-in"
              data-aos-delay="150"
            >
              <img
                src="assets/img/promise.svg"
                class="img-fluid"
                alt=""
              />
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
              <h4><b>We Promise. We Deliver.</b></h4>
              <p class="font-italic">Guaranteed Customer Satisfaction</p>

              <ul>
                <li>
                  Have an idea? Work with us and see how it goes. We promise to
                  go the extra mile for every project that we take onboard.
                </li>

                <h5>We’re just a Conversation away!</h5>
                <li>
                  We combine international outlook with the knowledge from
                  across the industries and technologies, into creating a
                  digital masterpiece that delivers result.
                </li>
              </ul>
              <a href="our-services.html" class="read-more">Read More <i class="icofont-long-arrow-right"></i></a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End We Promise. We Deliver. --> */}



      {/* <!-- ======= Technologies we work with ======= --> */}
      <section
        id="tech"
        class="portfolio"
      >
        <div class="container" data-aos="fade-up">
          <div class="section-title" >
            <h2>Technologies we work with</h2>
          </div>

          <div class="row">
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center"
            >
              <ul id="portfolio-flters">
               
                <li data-filter=".filter-a">App</li>
                <li data-filter=".filter-f">FrontEnd</li>
                <li data-filter=".filter-b">BackEnd</li>
                <li data-filter=".filter-d">DataBase</li>
                <li data-filter=".filter-dev">DevOps</li>
                <li data-filter=".filter-c">CMS</li>
              </ul>
            </div>
          </div>

          <div id ="tw">
            <div class="row portfolio-container">
              {/* <!-- ======= for all ======= --> */}

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-a"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/a4.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">Flutter</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-f"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/f2.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">React</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-b"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/b3.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">Node JS</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-d"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/d1.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">mongoDB</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-dev"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/dev1.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">aws</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-b"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/b1.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">django</h6>
              </div>

              {/* <!-- ======= for all end ======= --> */}

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-a"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/a1.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">Android</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-a"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/a2.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">iOS</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-a"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/f2.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">React Native</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-a"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/a5.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">Kotlin</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-a"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/a6.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">Swift</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-f"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/f1.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">Angular</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-f"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/f3.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">Vue</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-f"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/f4.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">Bootstrap</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-f"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/f5.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">jquery</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-f"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/f6.svg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">Semantic</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-b"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/b2.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">php</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-b"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/b4.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">Laravel</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-b"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/b5.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">Python</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-b"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/b6.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">.Net</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-d"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/d2.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">MySQL</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-d"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/d3.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">Firebase</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-d"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/d4.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">ORACLE</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-d"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/d5.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">postgreSQL</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-d"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/d6.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">dynamoDB</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-c"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/c1.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">WordPress</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-c"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/c2.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">Drupal</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-c"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/c3.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">SharePoint</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-c"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/c4.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">Wix</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-c"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/c5.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">Progress</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-c"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/c6.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">Joomla</h6>
              </div>

              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-dev"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/dev2.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">Google Cloud</h6>
              </div>
              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-dev"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/dev3.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">docker</h6>
              </div>
              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-dev"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/dev4.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">GitHub</h6>
              </div>
              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-dev"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/dev5.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">Gradle</h6>
              </div>
              <div
                class="col-lg-2 col-md-2 col-sm-4 col-4 portfolio-item filter-dev"
              >
                <div class="portfolio-wrap">
                  <img src="assets/img/dev6.jpeg" class="img-fluid" alt="" />
                </div>
                <h6 class="twa">Selenium</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Technologies we work with --> */}

    </div>  
        )  
    }  
}  
  
export default Index